import React from 'react'
import cx from 'classnames'

import Slider from 'react-slick'
import { Container } from '../container'
import styles from './styles.module.css'
import card1 from './images/image1.jpg'
import card2 from './images/image2.png'
import card3 from './images/image3.jpg'

export const SpecialOfferSection = ({ id }) => {
  const settings = {
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 0,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  }

  return (
    <section className={styles.specialOffer}>
      <Container>
        <h3 className="fancyUnderlineText sectionTitleNew text-center">
          Vyzkoušej naše krabičky <span>za výhodnou cenu</span>
        </h3>
        <p className={styles.subtitle}>
          Bud' mezi prvnimi! Objednej ted' se slevou.
        </p>
        <Slider {...settings} className="special-offer-carousel">
          <img src={card1} className={styles.offerCard} />
          <img src={card2} className={styles.offerCard} />
          <img src={card3} className={styles.offerCard} />
        </Slider>
      </Container>
    </section>
  )
}
