import { graphql, useStaticQuery } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import { DiscountSection } from '../components/discount'
import { FAQ2 } from '../components/faq2'
import { FoodCarouselSection3 } from '../components/food3'
import { HeroLiberec } from '../components/hero-liberec'
import { HowItWork2 } from '../components/howitwork-v2'
import { Loader } from '../components/loader'
import { MenuOrderInfo } from '../components/menuOrderInfo'
import { NutritionistSection } from '../components/nutritionist'
import { Order2 } from '../components/order-form2'
import { RedirectModal } from '../components/redirect-modal'
import { SaleFormModal } from '../components/sale-form-modal'
import { SaleFormModal2 } from '../components/sale-form-modal2'
import SEO from '../components/seo'
import { AffiliateContext } from '../contexts/AffiliateContext'
import { VegeMenuBlock } from '../components/vege-menu'
import { DeliverySection } from '../components/delivery-liberec'
import { HowDoWePrepare } from '../components/how-do-we-prepare'
import { Program2 } from '../components/program-v2'
import { Reviews2 } from '../components/reviews2'
import { SpecialOfferSection } from '../components/special-offer'
import { Hero3New } from "../components/hero3-new/hero3-new";
import { LocalizedLink } from "../components/localized-link";
import { useTranslation } from "react-i18next";

const PageLayout = ({ site }) => {
  const affiliateContext = useContext(AffiliateContext)
  const { isAffiliate } = affiliateContext.affiliate
  const { t } = useTranslation()

  function getRandomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
  
  // AB-test to display blueghost-version or current-version components
  const [websiteVersion, setWebsiteVersion] = useState("")
  const webVersions = ['new-calc-A', 'new-calc-B']
  
  useEffect(() => {
    const curVersion = localStorage.getItem("website-version")
    const randomIndex = getRandomInteger(1, 10) > 5 ? 1 : 0
    const randomVersion = webVersions[randomIndex]
    if (!curVersion || curVersion === undefined || !webVersions.includes(curVersion)) {
      localStorage.setItem("website-version", randomVersion)
      setWebsiteVersion(randomVersion)
    } else {
      setWebsiteVersion(curVersion)
    }
  }, [])
  
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
    window.dataLayer.push({
      pageURL: "/krabickova-dieta-liberec",
      testovani: websiteVersion,
      event: "ga.pageview"
    })
  }, [websiteVersion])

  const handleClick = () => {
    const isEn = document.location.pathname.includes("/en");
    const path = isEn ? document.location.pathname.replace(/^\/en/, "") : document.location.pathname;
    const dynamicPart = "/krabickova-dieta-liberec";
    const pathWithoutDynamicPart = path.replace(dynamicPart, "");
    const pathWithoutTrailingSlash = pathWithoutDynamicPart.replace(/\/$/, "");
    const url = `${window.location.origin}${pathWithoutTrailingSlash}/conditions.pdf`;

    window.open(url, "_blank");
  };

  return (
    <>
      <SEO title="Home" />
      {!isAffiliate && <SaleFormModal formName="sale-form" />}
      <RedirectModal />
      <SaleFormModal2 formName="Akce 2 dny" />
      <HeroLiberec />
      {/* <Hero3New/> */}
      {/* <div className="text-center" style={{fontSize: '22px'}}> */}
      {/* <Button2 color="outline" size="lg" className={styles.outsideButton}> */}
      {/* <LocalizedLink
         onClick={handleClick}
          target="_blank"
          style={{
            padding: "14px 0",
            textDecoration: "underline",
            fontWeight: '700',
            color: "rgb(68, 199, 137)",
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '16px'
          }}
        >
          {t("general.bannerLink")}
        </LocalizedLink> */}
      {/* </Button2> */}
      {/* </div> */}
      {/* <SpecialOfferSection /> */}
      <Order2 id="calculator2" />
      <Program2 />
      <MenuOrderInfo id="menu" />
      <FoodCarouselSection3 />
      <VegeMenuBlock id="vege-menu-section" />
      <NutritionistSection />
      <DeliverySection id="delivery-section" />
      <Reviews2 id="reviews" />
      <HowItWork2 />
      <HowDoWePrepare />
      <DiscountSection />
      <FAQ2 />
    </>
  )
}

const Liberec = ({ isAffiliate }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
              section
            }
          }
        }
      }
    `
  )

  return (
    <>
      {site ? <PageLayout site={site} isAffiliate={isAffiliate} /> : <Loader />}
    </>
  )
}

export default Liberec