import React from 'react'
import { useSmoothScroll } from '../../hooks/useSmoothScroll'
import cx from 'classnames'

import styles from './styles.module.css'
import { RatingDesk, RatingMob } from './icons'

export const HeroRating = () => {
  const scroll = useSmoothScroll()
  const scrollToReviewsSection = selector => {
    scroll.animateScroll(document.getElementById(selector))
  }

  return (
    <div className={styles.heroRatingBlock}>
      <a
        onClick={() => scrollToReviewsSection('reviews')}
        className={cx(styles.googleRatingDesk)}
      >
        <RatingDesk />
      </a>
      <a
        onClick={() => scrollToReviewsSection('reviews')}
        className={cx(styles.googleRatingMob)}
      >
        <RatingMob />
      </a>
      <a
        onClick={() => scrollToReviewsSection('reviews')}
        className={styles.heroRatingText}
      >
        300+ kladných zákaznických hodnocení
      </a>
    </div>
  )
}
